import {type ActionFunctionArgs, json, type LoaderFunctionArgs, type MetaFunction, redirect} from '@remix-run/node'
import {Form, Link, useActionData, useSearchParams} from '@remix-run/react'
import {useEffect, useState} from 'react'
import {convertOnToBoolean, useIsPending} from '#app/utils/misc.tsx'
import {AuthenticityTokenInput} from 'remix-utils/csrf/react'
import {HoneypotInputs} from 'remix-utils/honeypot/react'
import _ from 'lodash'
import {getFormProps, getInputProps, useForm} from '@conform-to/react'
import {z} from 'zod'
import {EmailSchema, PasswordSchema} from '#app/utils/user-validation.ts'
import {ErrorList, Field, PasswordField} from '#app/components/forms.tsx'
import {validateCSRF} from '#app/utils/csrf.server.ts'
import {checkHoneypot} from '#app/utils/honeypot.server.ts'
import {StatusButton} from '#app/components/ui/status-button.tsx'
import {post} from '#app/utils/refundr.server.aragorn.ts'
import {getZodConstraint, parseWithZod} from '@conform-to/zod'
import {type DashboardLoginResponse} from '#app/model/model.ts'
import {safeRedirect} from 'remix-utils/safe-redirect'
import {requireAnonymous} from '#app/utils/session.server.ts';

export const meta: MetaFunction = () => [{title: 'Login | refundr'}]

const LoginFormSchema = z.object({
    username: EmailSchema,
    password: PasswordSchema,
    redirectTo: z.string().optional(),
})


export async function action({request}: ActionFunctionArgs) {
    // Get user id from session if it exists else redirect to home page
    await requireAnonymous(request)
    const formData = await request.formData()
    // csrf stand for Cross-Site Request Forgery. This involves saving and verifying a token saved in browser cookie
    await validateCSRF(formData, request.headers)
    // Honeypot is a simple technique to prevent spam bots from submitting forms. It works by adding a hidden field to the form that bots will fill, but humans won't.
    checkHoneypot(formData)

    // form entries -> object
    const payload = convertOnToBoolean(Object.fromEntries(formData))

    // parse with zod
    const result = LoginFormSchema.safeParse(payload)

    // respond with zod errors
    if (!result.success) {
        const error = result.error.flatten()
        return {
            payload,
            formErrors: error.formErrors,
            fieldErrors: error.fieldErrors,
        }
    }

    const response = await post<{ email: string, password: string }, DashboardLoginResponse>(request, '/aws/login/', {
            email: result.data.username,
            password: result.data.password
        },
        true
    )

    // Return a form error if the message is not sent
    if (!response.success && (400 === response?.statusCode || 409 === response?.statusCode)) {
        return {
            payload,
            formErrors: [response.data],
            fieldErrors: {},
        }
    }

    if (403 === response?.statusCode || 'NEW_PASSWORD_REQUIRED' === response.data.mfaType) {
        return redirect(`/change-password?email=${result.data.username}`)
    }

    if ('MFA_SETUP' === response.data.mfaType) {
        return redirect(`/mfa-setup?email=${result.data.username}`)
    }

    if ('SOFTWARE_TOKEN_MFA' === response.data.mfaType) {
        return redirect(
            safeRedirect(`/mfa-challenge?email=${result.data.username}&session=${response.data.thirdPartySessionId}`)
        )
    }

    throw redirect('/500')

}

export async function loader({request}: LoaderFunctionArgs) {
    await requireAnonymous(request)
    return json({})
}

export default function LoginPage() {
    const actionData = useActionData<typeof action>()
    const isPending = useIsPending()
    const [searchParams] = useSearchParams()
    const redirectTo = searchParams.get('redirectTo')
    const [showPassword, setShowPassword] = useState(false)
    const authState = searchParams.get('state')

    const togglePasswordVisibility = () => {
        setShowPassword(prev => !prev)
    }

    const agentFriendlyParam = searchParams.get('friendlyId')
    useEffect(() => {
        const agent_friendly_id = window.localStorage.getItem('agent_friendly_id')
        if (_.isEmpty(agent_friendly_id)) {
            window.localStorage.setItem('agent_friendly_id', agentFriendlyParam ?? '')
        }
    }, [agentFriendlyParam])

    const [form, fields] = useForm({
        id: 'login-form',
        constraint: getZodConstraint(LoginFormSchema),
        defaultValue: {redirectTo},
        // @ts-ignore
        lastResult: actionData?.result,
        onValidate({formData}) {
            return parseWithZod(formData, {schema: LoginFormSchema})
        },
        shouldRevalidate: 'onBlur',
    })


    return (
        <div className="flex min-h-screen flex-col md:flex-row">
            {/* Left: Marketing Section */}
            <div
                className="dark-background order-last flex w-full items-center justify-center p-6 text-black md:order-none md:w-1/2">
                <div className="mt-5 flex flex-col items-center justify-center space-y-4">
                    <div>
                        <img className="block h-12 w-auto" src="/img/logo/refundr-white.png" alt="refundr" />
                    </div>
                    <div className="pt-4">
                        <img className="block w-auto" src="/img/tax-return-card-md.png" alt="refundr" />
                    </div>
                </div>
            </div>

            {/* Right: Login Section */}
            <div className="flex w-full items-center justify-center bg-white p-6 md:w-1/2">
                <div className="mt-5 w-full max-w-md bg-white mb-16">
                    {'unauthorized' === authState &&
                        <div className="flex flex-col items-center justify-center text-red-500 mb-6">
                            <div className="text-center font-bold">Access Denied</div>
                            <div className="text-center">Invalid credentials or insufficient permissions for this action.</div>
                        </div>
                    }
                    <div className="mb-6 flex items-center justify-center">
                        <div className="text-2xl font-bold text-gray-600">Welcome back!</div>
                    </div>
                    <Form method="POST" {...getFormProps(form)}>
                        <ErrorList errors={actionData?.formErrors}/>
                        <AuthenticityTokenInput/>
                        <HoneypotInputs/>
                        <div>
                            <Field
                                labelProps={{children: 'email', className: 'text-gray-700'}}
                                inputProps={{
                                    ...getInputProps(fields.username, {type: 'email'}),
                                    autoFocus: true,
                                    autoComplete: 'username',
                                    className: 'lowercase h-14 w-full rounded-lg bg-gray-100 px-3 py-2 text-black shadow border-gray-300',
                                    placeholder: 'Enter your email',
                                }}
                                errors={fields.username.errors}
                            />
                        </div>
                        <div>
                            <PasswordField
                                className="w-full"
                                showPassword={showPassword}
                                togglePasswordVisibility={togglePasswordVisibility}
                                inputProps={{
                                    ...getInputProps(fields.password, {type: 'password'}),
                                    autoComplete: 'current-password',
                                    placeholder: 'Enter your password',
                                }}
                                errors={fields.password.errors}
                            />
                        </div>
                        <div className="mb-6 flex items-center justify-end">
                            <div className="text-sm text-black">
                                <Link to="/forgot-password" className="text-body-xs font-semibold">
                                    forgot password?
                                </Link>
                            </div>

                        </div>
                        <div className="mb-4 text-center">
                            <input {...getInputProps(fields.redirectTo, {type: 'hidden'})} />
                            <StatusButton
                                className="h-12 w-full rounded-md bg-blue-500 px-4 py-2 text-white hover:bg-blue-600"
                                status={isPending ? 'pending' : form.status ?? 'idle'}
                                type="submit"
                                disabled={isPending}
                            >
                                Login
                            </StatusButton>
                        </div>
                        <div className="flex items-center justify-center gap-2 pt-4 font-bold">
                            <div className="inline-block cursor-pointer rounded-lg bg-gray-200 px-4 py-2 text-gray-700 hover:bg-gray-300">
                                <Link to={redirectTo ? `/signup?${encodeURIComponent(redirectTo)}` : '/signup'} className="text-black">
                                    Sign up
                                </Link>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}
